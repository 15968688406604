.cookie-consent {
    display: flex;
    flex-direction: row;
    position: fixed;
    bottom: 40px;
    /*    opacity: 0; */
    z-index: 1;
    background-color: white;
    border: 2px solid var(--consent-color);
    border-radius: 8px;
    max-width: var(--max-body-width);
    color: black;
    font-size: 1.6rem;
    margin: 5px 0px 5px 0px;
}
    .cookie-consent button {
        background-color: var(--consent-color);
        color: white;
    }
.persistent-cookie {
    height: 10vmin;
    pointer-events: none;
}
