html {
    font-size: 62.5%;
}
:root {
    --brand-color: #0D54B1;
    --consent-color: #0D54B1;
    --max-body-width: 1000px;
    --app-header-height: 36px;
}

.llc {
    color: var(--brand-color);
}

.App {
    text-align: center;
    min-height: 100vh;
}
.App-banner-logo {
    pointer-events: none;
}

/*@media (prefers-reduced-motion: no-preference) {
  .App-home-logo  {
    animation: App-logo-spin infinite 20s linear;
  }
}
*/
.App-header {
    background-color: var(--brand-color);
    min-width: 100vw;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 1.40rem;
    color: white;
    height: 32px;
}

.App-header-div {
    white-space: normal;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    vertical-align: middle;
    min-height: 100%;
}

.App-header-logo-div {
    display: inline-block;
    white-space: normal;
}
.App-body-viewport {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: calc(100vh - 32px - 30px - 10px);
    width: 100vw;
    overflow: auto;
}
.App-body {
    background-color: white;
    height: calc(100vh - 32px - 30px - 10px);
    max-width: var(--max-body-width);
    /*    min-width: 100vw; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: calc(10px + 1vmin);
    color: black;
    padding-top: 10px;
    margin-left: auto;
    margin-right: auto;
}

.App-footer {
    background-color: var(--brand-color);
    max-width: var(--max-body-width);
    height: 30px;
    width: 100%;
    display: table;
    white-space: nowrap;
    align-items: center;
    justify-content: center;
    font-size: 1.40rem;
    color: white;
    margin-left: auto;
    margin-right: auto;
}

    .App-footer div div {
        display: inline-block;
        white-space: normal;
        vertical-align: middle;
        padding-left: 10px;
        padding-right: 10px
    }

.App-link {
    color: var(--brand-color);
}

.App-header-link {
    color: white;
}

    .App-header-link:link {
        text-decoration: none;
    }

    .App-header-link:hover {
        text-decoration: underline;
    }

.App-footer-link {
    color: white;
}

    .App-footer-link:link {
        text-decoration: none;
    }

    .App-footer-link:visited {
        text-decoration: none;
    }

    .App-footer-link:hover {
        text-decoration: underline;
    }
/*
    .App-footer-link:active {
        text-decoration: none;
    }
*/
.app-copyright {
    color: black;
}

    .app-copyright a {
        color: black;
    }

        .app-copyright a:link {
            text-decoration: none;
        }

        .app-copyright a:visited {
            text-decoration: none;
        }

        .app-copyright a:hover {
            text-decoration: underline;
        }
.app-page {
    display: flex;
    flex-direction: column;
    height: 100%
}

h1 {
    color: var(--brand-color);
    margin-block-start: 0.10em;
    margin-block-end: 0.10em;
}

h2 {
    color: var(--brand-color);
}

h3 {
    color: var(--brand-color);
}

button {
    background-color: var(--brand-color);
    color: white;
    border: none;
    border-radius: 4px;
    padding: 5px;
    margin: 5px;
    font-size: .85em;
    cursor: pointer;
}
