.contact-page {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
}
.contact-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: center;
    width: 100%;
    height: 100%;
}
.contact-center {
    text-align: center;
    width: 100%;
    height: 100%;
}
.more-information-image {
    height: 10vmin;
    pointer-events: none;
}
.dotnet-bot-iot {
    height: 10vmin;
    pointer-events: none;
}
.silicon-mountain-image {
    height: 10vmin;
    pointer-events: none;
}
