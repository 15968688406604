.privacy-settings-content {
}

.privacy-settings-tabs {
    display: flex !important;
    flex-direction: row !important;
    align-content: flex-start;
    justify-content: flex-start;
    width: min-content;
}

.private-settings-tab-content {
    text-align: justify;
    width: 500px;
}

.private-settings-tab-content-header {
    display: flex;
    flex-direction: row;
}

    .private-settings-tab-content-header h2 {
        margin-block-start: 0px;
        padding-top: 0px;
    }
.privacy-read-only-toogle {
}
