.home-page {
    display: flex;
    flex-direction: column;
    height: 100%
}
.home-quotes {
    display: flex;
    flex-direction: row;
}
.home-logo {
    height: 40vmin;
    pointer-events: none;
}
.homeQuote {
    color: #0D54B1;
    font-size: 2.25em;
    margin-top: 30px;
    margin-left: 15px;
    margin-right: 15px;
}
.home-dotnet {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: auto;
}
.dotnet-bot {
    height: 10vmin;
    pointer-events: none;
}
.dotnet-shop {
    display: flex;
    height: 100%;
    align-items: flex-end;
    justify-content: flex-start;
}
